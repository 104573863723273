<template>
    <list-page
        page-title="Accounts"
        page-icon="mdi-folder-outline"
        collection-name="account"
        :items-per-page="20"
        add-dialog-title="Add Account"
        is-filter
    />
</template>

<script>
import ListPage from '../../../components/pages/List';


export default {
    name: 'AccountList',
    components: { ListPage },

};
</script>

<style scoped></style>
